/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import Cookies from 'universal-cookie';

export const onClientEntry = () => {
  const cookies = new Cookies()
  const cx = cookies.get('goapps_prefs')
  const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development"
  // const body = document.getElementsByTagName('body')[0]
  const head = document.getElementsByTagName('head')[0]

  if (activeEnv === "production" && cx && cx.set) {
      
    // // set any performance cookies
    if (cx.performance) {
      // freshdesk contact from widget
      const body = document.getElementsByTagName('body')[0]
      const fwSettingsScript = document.createElement('script')
      fwSettingsScript.type = `text/javascript`
      fwSettingsScript.innerHTML = `
        window.fwSettings={
        'widget_id':151000003032
        };
        !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
      `
      body.appendChild(fwSettingsScript)
  
      const fwLoadScript = document.createElement('script')
      fwLoadScript.type = 'text/javascript'
      fwLoadScript.async = true
      fwLoadScript.defer = true
      fwLoadScript.src = 'https://widget.freshworks.com/widgets/151000003032.js'
      body.appendChild(fwLoadScript)
    }
    if (cx.analytics) {
      // google analytics
      const gtagSrc = document.createElement(`script`)
      gtagSrc.async = true
      gtagSrc.src = "https://www.googletagmanager.com/gtag/js?id=G-QPSRVS545J"
      head.appendChild(gtagSrc)
      const gtagInit = document.createElement(`script`)
      gtagInit.type = `text/javascript`
      gtagInit.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-QPSRVS545J');
      `
      head.appendChild(gtagInit)

      // facebook
      const fbScript = document.createElement(`script`)
      fbScript.type = `text/javascript`
      fbScript.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '803724737362228');
        fbq('track', 'PageView');
      `
      head.appendChild(fbScript)

      const fbNoScript = document.createElement('noscript');
      fbNoScript.innerHTML = `
        <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=803724737362228&ev=PageView&noscript=1" />
      `
      head.appendChild(fbNoScript)
    }
  }

}